<template>
    <div>
        <b-modal ref="signatureModal" size="md" centered hide-header hide-footer hide-header-close>
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1 >{{heading}}</app-heading-1>
                    <!-- <app-normal-text>See who signed and woh didn’t</app-normal-text> -->
                </div>
                <div class="my-2">
                    <div class="d-flex align-items-center justify-content-between my-1" v-for="(s,index) in signatures" :key="index">
                        <div class="d-flex align-items-center">
                            <b-avatar
                            class="pull-up"
                            :text="s.name.slice(0,1).toUpperCase()"
                              :variant="colors[index]"
                            />
                            <div class="sign-label ml-1">{{s.name?s.name:s.email}}</div>
                        </div>
                        <b-badge :variant="s.signed?'light-success':'light-secondary'">{{s.signed?'Signed':'Pending'}}</b-badge>
                    </div>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary"  @click="hideModal">Close</app-simple-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppNormalText from "@/components/app-components/AppNormalText"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"

import { BModal, VBModal, BAvatar, BBadge } from 'bootstrap-vue'
export default {
    components: {
        AppHeading1,
        AppSimpleButton,
        BModal,
        VBModal,
        AppNormalText,
        BAvatar,
        BBadge
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            signatures:[],
            heading:'Signature',
            colors:['light-primary','light-info','light-success','light-danger','light-secondary'],
        }
    },
    methods:{
        showModal(signatures,heading){
            this.$refs.signatureModal.show()
            this.setSignature(signatures,heading)
            
        },
        hideModal(){
            this.$refs.signatureModal.hide()
        },
        setSignature(signatures,heading){
            this.signatures=signatures
            this.heading=heading
        },
    },
}
</script>
<style>
.sign-label{
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: #000000;
    text-transform: capitalize;
}
</style>